import { Logger } from '../utils';

const logger = Logger.getInstance('player');

const MAX_IDLE_TIME_BEFORE_RESEEKING_MS = 6000; // If idle for > 1/2 of a segment duration, re-seeking to live could help
const IDLE_RESEEK_DELAY_MS = 50; // Avoid possible browser race conditions in re-seeking during play event

export function normalizeError(error, source) {
  // This error object comes from the Clappr event. Per the source code, depending on playback
  // and circumstance, the passed object could be:
  //   a) string error description
  //   b) dictionary with `message` and `data` keys
  //   c) dictionary with `evt` and `data` keys from hls.js, where `data` is an object with `type`, `details`, and a whole bunch of other keys
  //   d) error object from native HTML5 video element
  //
  // Please note, per the HTML5 spec, these are the following error code values:
  //   MEDIA_ERR_ABORTED (1) The fetching process for the media resource was aborted by the user agent at the user's request.
  //   MEDIA_ERR_NETWORK (2) A network error of some description caused the user agent to stop fetching the media resource, after the resource was established to be usable.
  //   MEDIA_ERR_DECODE (3) An error of some description occurred while decoding the media resource, after the resource was established to be usable.
  //   MEDIA_ERR_SRC_NOT_SUPPORTED (4) The media resource indicated by the src attribute was not suitable.
  //
  // Let's try to normalize the reported error a touch
  error = error || {};
  const code = error.code || (error.data && error.data.code);
  let message = error.message;
  if (!message && error.data) {
    message = error.data.details /*hlsError, cannot be stringified*/ || JSON.stringify(error.data);
  } else {
    message = error.toString();
  }
  if ((message = '[object MediaError]')) {
    message = 'MediaError occurred';
  }
  let errorObject = {
    message: message,
    code: code,
    data: error.data,
  };
  if (source) {
    errorObject.source = source;
  }
  return errorObject;
}

function doAutoPlay(player, autoplayOption) {
  var playback = player.core.getCurrentPlayback();
  if (!playback) {
    // yolo
    player.play();
    return;
  }

  const isHlsJs = playback.name === 'hls';
  const isHtml5 = playback.name === 'html5_video';
  if (!isHlsJs && !isHtml5) {
    // also yolo
    player.play();
    return;
  }

  // The code below this point is an amalgamation of the hls.js playback play() method
  // (https://github.com/clappr/clappr/blob/dev/src/playbacks/hls/hls.js#L384-L390) and the HTML5
  // video playback play() method
  // (https://github.com/clappr/clappr/blob/dev/src/playbacks/html5_video/html5_video.js#L255-L265).
  // It is modified to handle the promise returned by the call to playback.el.play() and try muting
  // the video if it failed.

  // Note the call to playback._setup(); is not necessary because it will have already been setup
  // below, or is unnecessary if not using the hls.js playback.

  playback.trigger('playback:play:intent');
  playback._stopped = false;
  playback._setupSrc(playback._src);
  playback._handleBufferingEvents();
  let promise = playback.el.play();
  if (promise && promise.catch) {
    promise.catch((e) => {
      logger.log('Error trying to autoplay:', e);

      // If autoplayOption is just `true`, then we will not try harder, so we're done.
      if (autoplayOption !== 'try-muted') return;

      // Try muting first
      const playerWasMuted = playback.el.muted === true || playback.el.volume === 0;
      player.mute();
      let newPromise = playback.el.play();
      if (newPromise && newPromise.catch) {
        newPromise.catch((e2) => {
          logger.log('Error trying to autoplay even after muting:', e2);
          if (!playerWasMuted) {
            // If the player was NOT muted before we tried to mute it to play, and this also
            // failed, then go ahead and unmute to put it back in the state it was in. That way
            // if/when the user clicks play, it should be in the same state it was in before (see
            // PLATFORM-3977).
            player.unmute();
          }
        });
      }
      if (newPromise && newPromise.then) {
        newPromise.then(() => {
          // Signal what we just did
          logger.log('firing autoplay muted event');
          playback.trigger('custom:autoplay:muted');
        });
      }
    });
  }
  if (promise && promise.then) {
    promise.then(() => {
      // playback might have been muted previously, either by the user or by the logic above
      if (playback.el.muted === true || playback.el.volume === 0) {
        /* clappr isMuted function is logically inverted as of v0.3.1 */
        logger.log('firing autoplay muted event due to playback already muted when starting');
        playback.trigger('custom:autoplay:muted');
      }
    });
  }

  if (isHlsJs) {
    playback._startTimeUpdateTimer();
  }
}

export function initHlsJsPlayback(player, options) {
  // Work around some odditities in how Clappr spins up its hls.js playback
  // XXX: we reach into private APIs a bit here, so this could break.
  var playback = player.core.getCurrentPlayback();
  if (!playback || playback.name != 'hls') {
    options.autoplay && doAutoPlay(player, options.autoplay);
    return;
  }

  // Still set up the HLS context immediately even if we aren't going to autoplay so that we get the
  // thumbnail preview of the first frame
  var hls = playback._hls;
  if (!hls) {
    playback._setup();
    hls = playback._hls;
  }

  if (options.startAtHighestQuality) {
    // We need to force this a bit because the default logic in hls.js
    // starts at the lowest quality level to bandwidth test.
    hls.on('hlsManifestParsed', (evt, data) => {
      hls.startLevel = data.levels.length - 1;
      hls.startLoad();
      options.autoplay && doAutoPlay(player, options.autoplay);
    });
  } else {
    // For normal playback, use hls.js logic to pick quality level based on bandwidth estimate
    hls.startLevel = -1;
    hls.startLoad();
    options.autoplay && doAutoPlay(player, options.autoplay);
  }

  if (options.isLive && !options.autoplay) {
    // Need to catch first play event and seek to live (see https://github.com/boxcast/boxcast_js/issues/67)
    var initTime = Date.now();
    player.listenToOnce(player, 'play', () => {
      if (Date.now() - initTime > MAX_IDLE_TIME_BEFORE_RESEEKING_MS) {
        setTimeout(() => player.seekPercentage(100), IDLE_RESEEK_DELAY_MS);
      }
    });
  }
}
